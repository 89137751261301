import React, { useState, useEffect } from 'react';
import CreateAccountsReceivableForm from '../../../../containers/Finance/AccountsReceivable/CreateAccountsReceivableForm';
import useApi from '../../../../hooks/useApi';
import { useHistory } from 'react-router-dom';
import months from '../../../../helpers/enum/months';
import { showSuccessNotification } from '../../../../store/app/actions';
import { useDispatch } from 'react-redux';
import AdminSidebar from '../../AdminSidebar';
import formatNumber from '../../../../utils/formatNumber';

function CreateAccountsReceivable() {
  const {
    clients: { getAllClients },
    countries: { getAllCountries },
    lineItems: { getLineItemsByIds },
    bankAccounts: { getBankAccountById },
    accountsReceivables: { getLatestInvoiceNumber, createAccountsReceivable },
  } = useApi();

  const today = new Date();

  const {
    location: { state },
  } = useHistory();
  const history = useHistory();

  const dispatch = useDispatch();

  const [clientOptions, setClientOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bankAccountOptions, setBankAccountOptions] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [invoiceData, setInvoiceData] = useState({
    invoice_number: null,
    issue_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())),
    due_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() + 30)),
    client_id: null,
    revenue: null,
    vat: null,
    revenue_and_vat: null,
    sent: false,
    paid: null,
    bank_account_id: null,
    metadata: {
      to: {
        name: '',
        address: '',
        postal_code: '',
        city: '',
        country: '',
        vat_number: '',
      },
      from: {
        name: 'Thaleria S.A.',
        address: 'Avenue des Arts 56',
        postal_code: '1000',
        city: 'Brussels',
        country: 'Belgium',
        vat_number: 'BE 0704.867.821',
      },
      subject: 'Thaleria services',
      header_comment: `Please find below the details of your invoice containing the references of the framework contract and/or agreement, specific contract and/or purchase order, period covered, price per unit, quantity and unit of measurement.`,
      footer_comment: `Please pay the amount of {{total_incl_vat}} to the account {{receivables_iban}} by {{due_date}}.`,
      footer_data: {
        name: 'Thaleria S.A.',
        email: 'finance@thaleria.com',
        phone: '+32 474 95 74 11',
        vat_number: 'BE 0704.867.821',
        iban: '',
        bic_swift: '',
      },
      showReferences: {
        salesOrder: true,
        specificContract: true,
        partnerContract: true,
        frameworkContract: true,
      },
      merge_lines: false,
      hide_period: false,
      vat_disclaimer: null,
    },
  });

  const getInitialData = async () => {
    let countries = [];
    await getAllCountries().then(data => (countries = data));

    let clients = [];
    await getAllClients().then(data => {
      clients = data.map(c => {
        return {
          label: c.name,
          value: c.id,
          clientDetails: {
            name: c.name,
            address: c.address,
            postal_code: c.postal_code,
            city: c.city,
            country: countries.find(ct => ct.id === c.country_id)?.name,
            vat_number: c.vat_number,
            country_id: c.country_id,
            type: c.type,
          },
        };
      });
    });

    let invoiceNb;
    await getLatestInvoiceNumber()
      .then(res => {
        invoiceNb = res.latestAvailableNumber;
      })
      .catch(err => {
        invoiceNb = null;
      });

    return {
      countries,
      clients,
      invoiceNb,
    };
  };

  const fetchBankDetails = async arrayOfIds => {
    const bankAccounts = await Promise.all(
      arrayOfIds.map(async id => {
        const d = await getBankAccountById(id);
        return { ...d, label: d.name, value: d.id };
      }),
    );

    const uniqueBankAccounts = Array.from(new Map(bankAccounts.map(item => [item.id, item])).values());

    return uniqueBankAccounts;
  };

  useEffect(() => {
    //when line items change

    //update bank accounts
    const newBankAccountIds = [];
    const removedBankAccountIds = [];

    //update invoice total
    const newTotals = {
      revenue: 0,
      vat: 0,
      revenue_and_vat: 0,
    };

    bankAccountOptions.forEach(account => {
      if (!lineItems.find(item => item.bank_account_id === account.id)) {
        removedBankAccountIds.push(account.id);
      }
    });

    lineItems.forEach(line => {
      let lineTotal = formatNumber(line.units * line.price);
      newTotals.revenue += lineTotal;
      newTotals.vat += formatNumber(lineTotal * (line.vat_rate / 100));

      if (!bankAccountOptions.find(account => account.id === line.bank_account_id)) {
        newBankAccountIds.push(line.bank_account_id);
      }
    });

    //update bank accounts if some were added or removed
    if (newBankAccountIds.length || removedBankAccountIds.length) {
      //need to iterate manually between the three scnearios because i was having issues with the async fetch and this was the quickest fix
      if (newBankAccountIds.length && removedBankAccountIds.length) {
        fetchBankDetails(newBankAccountIds).then(newBankAccounts => {
          //add and remove accounts
          const updatedBankAccounts = [...bankAccountOptions, ...newBankAccounts].filter(account => {
            return removedBankAccountIds.find(id => id === account.id) ? false : true;
          });
          setBankAccountOptions(updatedBankAccounts);
        });
      } else if (newBankAccountIds.length) {
        //add accounts
        fetchBankDetails(newBankAccountIds).then(newBankAccounts => {
          const updatedBankAccounts = [...bankAccountOptions, ...newBankAccounts];
          setBankAccountOptions(updatedBankAccounts);
        });
      } else if (removedBankAccountIds.length) {
        //remove accounts
        const updatedBankAccounts = bankAccountOptions.filter(account => {
          return removedBankAccountIds.find(id => id === account.id) ? false : true;
        });
        setBankAccountOptions(updatedBankAccounts);
      }
    }

    newTotals.revenue_and_vat = newTotals.revenue + newTotals.vat;

    setInvoiceData(prev => ({ ...prev, ...newTotals }));
  }, [lineItems]);

  useEffect(() => {
    if (state?.invoicingLinesIds?.length) {
      getInitialData().then(({ clients, invoiceNb }) => {
        setClientOptions(clients);
        getLineItemsByIds(state.invoicingLinesIds).then(data => {
          const foundClient = clients.find(c => c.value === data[0].salesOrder.client_id);
          let formattedData = {
            ...invoiceData,
            invoice_number: invoiceNb,
            client_id: data[0].salesOrder.client_id,
            metadata: {
              ...invoiceData.metadata,
              to: {
                name: foundClient.clientDetails.name,
                address: foundClient.clientDetails.address,
                postal_code: foundClient.clientDetails.postal_code,
                city: foundClient.clientDetails.city,
                country: foundClient.clientDetails.country,
                vat_number: foundClient.clientDetails.vat_number,
              },
            },
          };
          setInvoiceData(formattedData);
          let formattedLineItems = data.map(item => ({
            id: item?.id,
            description: item?.description || `Services provided by ${item.user.full_name}`,
            units: item.units,
            uom: item.uom,
            price: item.price,
            vat_rate: item.vat_rate,
            type: item.type,
            staff_order_id: item.staff_order_id,
            partner_contract_id: item.partner_contract_id,
            bank_account_id: item.salesFwc.bank_account_id,
            framework_contract_id: item.framework_contract_id,
            specific_contract_id: item.specific_contract_id,
            sales_order_id: item.sales_order_id,
            timesheet_id: item?.timesheet?.id || null,
            user_id: item.user_id,
            to_date: item.to_date,
            from_date: item.from_date,
            metadata: JSON.parse(item.metadata),
            total: item.total,
            vat: item.vat,
            total_and_vat: item.total_and_vat,
          }));
          setLineItems(formattedLineItems);
        });
      });
    } else {
      getInitialData().then(({ clients, invoiceNb }) => {
        setClientOptions(clients);
        setInvoiceData(prev => ({ ...prev, invoice_number: invoiceNb }));
      });
    }
  }, [state]);

  const handleAddAccountsReceivable = () => {
    const selectedClientDetails = clientOptions.find(c => c.value === invoiceData?.client_id).clientDetails;
    let errors = [];
    if (!invoiceData.invoice_number) errors.push({ field: 'invoice_number', msg: 'Required' });
    if (!invoiceData.issue_date) errors.push({ field: 'issue_date', msg: 'Required' });
    if (!invoiceData.due_date) errors.push({ field: 'due_date', msg: 'Required' });
    if (!invoiceData.client_id) errors.push({ field: 'client_id', msg: 'Required' });
    if (
      invoiceData.client_id &&
      (!selectedClientDetails.address ||
        !selectedClientDetails.vat_number ||
        !selectedClientDetails.postal_code ||
        !selectedClientDetails.country ||
        !selectedClientDetails.city)
    )
      errors.push({ field: 'client_id', msg: 'Missing financial data' });
    if (!invoiceData.bank_account_id) errors.push({ field: 'bank_account_id', msg: 'Required' });
    if (!invoiceData.metadata.subject) errors.push({ field: 'subject', msg: 'Required' });
    if (!invoiceData.metadata.footer_comment) errors.push({ field: 'footer_comment', msg: 'Required' });
    if (!invoiceData.metadata.header_comment) errors.push({ field: 'header_comment', msg: 'Required' });
    if (
      !lineItems.length ||
      lineItems.find(l => !l.units || !l.price || !l.uom || (l.vat_rate !== 0 && !l.vat_rate) || !l.from_date)
    )
      errors.push({ field: 'lineItems', msg: 'Required' });
    if (errors.length) return setFormErrors(errors);
    else {
      setFormErrors([]);
      const formattedLineItems = lineItems.map(el => {
        if (!el?.to_date) el.to_date = el.from_date;
        const _el = { ...el, price: Number(el.price), units: Number(el.units) };
        delete _el.bank_account_id;
        //not required in backend since it is calculated there
        delete _el.totals;
        delete _el.total;
        delete _el.vat;
        delete _el.total_and_vat;
        return _el;
      });
      //identify what changed and only send that

      const formattedData = {
        invoice: {
          ...invoiceData,
        },
        lineItems: formattedLineItems,
      };
      setIsLoading('create');
      createAccountsReceivable(formattedData)
        .then(res => {
          dispatch(showSuccessNotification('Account receivable successfully created'));
          if (state?.invoicingLinesIds) {
            history.push('/admin-panel/finance/invoicing-lines');
          } else history.push('/admin-panel/finance/accounts-receivable');
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  };

  const pages = [
    { name: 'Finance Manager', href: '/admin-panel/finance/invoicing-items', current: false },
    { name: `Accounts Receivable`, href: '/admin-panel/finance/accounts-receivable', current: false },
    { name: `Create`, href: '/admin-panel/finance/accounts-receivable/create', current: true },
  ];

  return (
    <AdminSidebar noPadding pages={pages}>
      <CreateAccountsReceivableForm
        invoiceData={invoiceData}
        setInvoiceData={setInvoiceData}
        clientOptions={clientOptions}
        lineItems={lineItems}
        setLineItems={setLineItems}
        bankAccountOptions={bankAccountOptions}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        handleAddAccountsReceivable={handleAddAccountsReceivable}
        isLoading={isLoading}
      />
    </AdminSidebar>
  );
}

export default CreateAccountsReceivable;
