import React from 'react';
import Modal from '../../../components/Modals/Modal';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import DividerWithLabel from '../../../components/Dividers/DividerWithLabel';
import { date } from '../../../helpers/date';
import WarningTooltip from '../../../components/Tooltips/WarningTooltip';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import Error from '../../../components/Error/Error';
import { staffContractType } from '../../../helpers/enum/staffContractType';
import useApi from '../../../hooks/useApi';
import contractInvoicingTypes from '../../../helpers/enum/contractInvoicingTypes';
import formatCurrency from '../../../utils/formatCurrency';
import formatNumber from '../../../utils/formatNumber';

function SummaryModal({
  show,
  hide,
  endClientFWC,
  primeFWC,
  specificContract,
  salesOrder,
  purchaseOrder,
  user,
  staffContract,
  dateErrors,
  setShowSuccessModal,
  clients,
  contacts,
  countries,
}) {
  const {
    purchaseOrders: { createAssociatedContracts },
  } = useApi();

  const returnWarningTooltip = (text, errorLabel) => {
    return (
      <span className="flex">
        {text}{' '}
        {dateErrors.some(err => err.errors.includes(errorLabel)) ? (
          <WarningTooltip>
            <ul className="w-80 space-y-1 normal-case">
              {dateErrors
                .filter(err => err.errors.includes(errorLabel))
                .map(e => (
                  <li>&#8226; {e.msg}</li>
                ))}
            </ul>
          </WarningTooltip>
        ) : (
          ''
        )}
      </span>
    );
  };

  //handle add entry click
  const handleAddEntryClick = async () => {
    try {
      let salesOrderToCreate;
      let specificContractToCreate;
      if (salesOrder?.id) {
        salesOrderToCreate = {
          id: salesOrder.id,
        };
      } else {
        salesOrderToCreate = {
          ...salesOrder,
          days_ordered: Number(salesOrder.days_ordered) || null,
          value: salesOrder?.invoicing_type === contractInvoicingTypes.numbers.fixedPrice ? Number(salesOrder?.value) : null,
          invoicing_type: Number(salesOrder?.invoicing_type) || null,
        };
      }

      if (specificContract?.id) {
        specificContractToCreate = {
          id: specificContract.id,
        };
      } else {
        specificContractToCreate = {
          ...specificContract,
          days_ordered: Number(specificContract.days_ordered) || null,
          value:
            specificContract?.invoicing_type === contractInvoicingTypes.numbers.fixedPrice
              ? Number(specificContract?.value)
              : null,
          invoicing_type: Number(specificContract?.invoicing_type) || null,
        };
      }

      if (purchaseOrder?.invoicing_type === contractInvoicingTypes.numbers.timeAndMeans) delete purchaseOrder?.value;

      const orderToCreate = {
        ...purchaseOrder,
        staff_contract_id: staffContract.id,
        framework_contract_id: endClientFWC.id,
        partner_contract_id: primeFWC.id,
        user_id: user.id,
        daily_rate: Number(purchaseOrder.daily_rate) || null,
        days_ordered: Number(purchaseOrder.days_ordered) || null,
        sales_order: salesOrderToCreate,
        specific_contract: specificContractToCreate,
      };

      orderToCreate.value = Number(orderToCreate?.value) || null;
      orderToCreate.invoicing_type = Number(orderToCreate?.invoicing_type) || null;

      const newContracts = await createAssociatedContracts(orderToCreate);

      if (newContracts) {
        hide();
        setShowSuccessModal(true);
      }
    } catch (err) {
      hide();
    }
  };

  return (
    <Modal show={show} hide={hide} title="Purchase order summary" size="xl">
      {!!dateErrors.length && (
        <Error
          className="mb-3"
          outline
          message="There are some date inconsistencies in the contracts. Please solve them before adding the entry."
        />
      )}
      <DividerWithLabel
        labelClass="text-sm font-bold text-thaleria-blue-400 underline decoration-dotted underline-offset-4"
        label={returnWarningTooltip('End-client framework contract', 'endClientFWC')}
      />
      <dl className="my-3 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-2">
        <SimpleEntry label={'Contract reference'} data={endClientFWC?.contract_ref || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Start date'} data={date(endClientFWC?.contract_start || '-')} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'End date'}
          data={endClientFWC?.contract_end ? date(endClientFWC?.contract_end) : 'Open-ended'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Clients'}
          data={endClientFWC?.clients?.map(obj => obj.name).join(', ') || '-'}
          style={`sm:col-span-1`}
        />
      </dl>
      <DividerWithLabel
        labelClass="text-sm font-bold text-thaleria-blue-400 underline decoration-dotted underline-offset-4"
        label={returnWarningTooltip('Sales framework contract', 'primeFWC')}
      />
      <dl className="my-3 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-2">
        <SimpleEntry label={'Contract reference'} data={primeFWC?.contract_ref || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Start date'} data={date(primeFWC?.contract_start || '-')} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'End date'}
          data={primeFWC?.contract_end ? date(primeFWC?.contract_end) : 'Open-ended'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry label={'Clients'} data={primeFWC?.clients?.map(obj => obj.name).join(', ') || '-'} style={`sm:col-span-1`} />
      </dl>
      <DividerWithLabel
        labelClass="text-sm font-bold text-thaleria-blue-400 underline decoration-dotted underline-offset-4"
        label={returnWarningTooltip('Specific contract', 'specificContract')}
      />
      <dl className="my-3 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-2">
        <SimpleEntry label={'Contract reference'} data={specificContract?.contract_ref} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'Start date'}
          data={specificContract?.order_start ? date(specificContract?.order_start) : '-'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'End date'}
          data={specificContract?.order_end ? date(specificContract?.order_end) : 'Open-ended'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Invoicing type'}
          data={contractInvoicingTypes?.strings[specificContract?.invoicing_type]}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Price (€/day)'}
          data={specificContract?.daily_rate && formatCurrency(specificContract.daily_rate, true)}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Days ordered'}
          data={specificContract?.days_ordered ? formatNumber(specificContract?.days_ordered, true) : 'FTE'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Value (€)'}
          data={specificContract?.value ? formatCurrency(specificContract?.value, true) : '-'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry label={'Department'} data={specificContract?.department} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'Client'}
          data={clients.find(u => u.id === specificContract?.client_id)?.name}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Country'}
          data={countries.find(u => u.id === specificContract?.country_id)?.name}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Point of Contact'}
          data={contacts.find(u => u.id === specificContract.point_of_contact_id)?.name}
          style={`sm:col-span-1`}
        />
      </dl>
      <DividerWithLabel
        labelClass="text-sm font-bold text-thaleria-blue-400 underline decoration-dotted underline-offset-4"
        label={returnWarningTooltip('Sales order', 'salesOrder')}
      />
      <dl className="my-3 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-2">
        <SimpleEntry label={'Order reference'} data={salesOrder?.order_ref} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'Start date'}
          data={salesOrder?.order_start ? date(salesOrder?.order_start) : '-'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'End date'}
          data={salesOrder?.order_end ? date(salesOrder?.order_end) : 'Open-ended'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Invoicing type'}
          data={contractInvoicingTypes.strings[salesOrder?.invoicing_type]}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Price (€/day)'}
          data={salesOrder?.daily_rate && formatCurrency(salesOrder.daily_rate, false)}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Days ordered'}
          data={salesOrder?.days_ordered ? formatNumber(salesOrder?.days_ordered, true) : 'FTE'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Value (€)'}
          data={salesOrder?.value ? formatCurrency(salesOrder?.value, false) : '-'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry label={'Department'} data={salesOrder?.department} style={`sm:col-span-1`} />
        <SimpleEntry label={'Client'} data={clients.find(u => u.id === salesOrder?.client_id)?.name} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'Point of Contact'}
          data={contacts.find(u => u.id === salesOrder.point_of_contact_id)?.name}
          style={`sm:col-span-1`}
        />
      </dl>
      <DividerWithLabel
        labelClass="text-sm font-bold text-thaleria-blue-400 underline decoration-dotted underline-offset-4"
        label={returnWarningTooltip('Purchase order', 'purchaseOrder')}
      />
      <dl className="my-3 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-2">
        <SimpleEntry label={'Order reference'} data={purchaseOrder?.order_ref} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'Start date'}
          data={purchaseOrder?.order_start ? date(purchaseOrder?.order_start) : '-'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'End date'}
          data={purchaseOrder?.order_end ? date(purchaseOrder?.order_end) : 'Open-ended'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Invoicing type'}
          data={contractInvoicingTypes.strings[purchaseOrder?.invoicing_type]}
          style={`sm:col-span-1`}
        />
        {staffContract?.contract_type_id !== staffContractType.EMPLOYEE && (
          <SimpleEntry
            label={'Price (€/day)'}
            data={purchaseOrder?.daily_rate && formatCurrency(purchaseOrder.daily_rate, false)}
            style={`sm:col-span-1`}
          />
        )}
        <SimpleEntry
          label={'Days ordered'}
          data={purchaseOrder?.days_ordered ? formatNumber(purchaseOrder?.days_ordered, true) : 'FTE'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry
          label={'Value (€)'}
          data={purchaseOrder?.value ? formatCurrency(purchaseOrder?.value, false) : '-'}
          style={`sm:col-span-1`}
        />
        <SimpleEntry label={'Timesheet'} data={purchaseOrder?.timesheet_type} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'Point of Contact'}
          data={contacts.find(u => u.id === purchaseOrder.point_of_contact_id)?.name}
          style={`sm:col-span-1`}
        />
      </dl>
      <DividerWithLabel
        labelClass="text-sm font-bold text-thaleria-blue-400 underline decoration-dotted underline-offset-4"
        label={returnWarningTooltip('Staff contract', 'staffContract')}
      />
      <dl className="my-3 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-2">
        <SimpleEntry label={'Consultant'} data={user?.full_name} style={`sm:col-span-1`} />
        <SimpleEntry label={'Contract type'} data={staffContract?.contractType?.contract_type || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Country'} data={staffContract?.country?.name || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Position'} data={staffContract?.position || '-'} style={`sm:col-span-1`} />
        <SimpleEntry label={'Start date'} data={date(staffContract?.contract_start || '-')} style={`sm:col-span-1`} />
        <SimpleEntry
          label={'End date'}
          data={staffContract?.contract_end ? date(staffContract?.contract_end) : 'Open-ended'}
          style={`sm:col-span-1`}
        />
      </dl>
      <div className="flex flex-row-reverse mt-6 ">
        <ButtonPrimary onClick={handleAddEntryClick} disable={dateErrors.length} text="Add contract" />
        <ButtonWhite style="mr-2" onClick={hide} text="Cancel" />
      </div>
    </Modal>
  );
}

export default SummaryModal;
