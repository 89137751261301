import { useState, useEffect, useMemo } from 'react';
import Modal from '../../components/Modals/Modal';
import InputSimple from '../../components/Inputs/InputSimple';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import { ClipLoader } from 'react-spinners';
import ReactSelect from '../../components/Inputs/ReactSelect';
import useApi from '../../hooks/useApi';
import { showSuccessNotification } from '../../store/app/actions';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import InputRadio from '../../components/Inputs/InputRadio';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';
import Error from '../../components/Error/Error';
import FinanceFileNameMacros from '../../components/Document/FileNameSettings/FinanceFileNameMacros';
import TimesheetFileNameMacros from '../../components/Document/FileNameSettings/TimesheetFileNameMacros';

const FileNameSettings = ({ show, setShow, fileType, loading, onAccept, onCancel }) => {
  const dispatch = useDispatch();
  const today = new Date();
  const [fileNameTemplate, setFileNameTemplate] = useState('');
  const [separator, setSeparator] = useState('-');
  const [userSettings, setUserSettings] = useState({ accountsReceivable: {}, creditNotes: {}, timesheets: {} });
  const [savingTemplate, setSavingTemplate] = useState(false);
  const [selectedDateFormat, setSelectedDateFormat] = useState({ label: '', value: '' });
  const [errors, setErrors] = useState([]);

  const {
    settings: { fetchFileNameSettings, updateFileNameSettings },
  } = useApi();

  const spacingOptions = { Hyphen: '-', Space: ' ', Underscore: '_' };

  //date format options should reflect the separator selected by the user
  const dateFormatOptions = useMemo(() => {
    let _dateFormatOptions = [
      { label: `${format(today, `MM${separator}yy`)}`, value: `MM${separator}yy` },
      { label: `${format(today, `MM${separator}yyyy`)}`, value: `MM${separator}yyyy` },
      { label: `${format(today, `yyyy${separator}MM`)}`, value: `yyyy${separator}MM` },
      { label: `${format(today, `MMM${separator}yyyy`)}`, value: `MMM${separator}yyyy` },
      { label: `${format(today, `MMM${separator}yy`)}`, value: `MMM${separator}yy` },
      { label: `${format(today, `QQQ${separator}yyyy`)}`, value: `QQQ${separator}yyyy` },
      { label: `${format(today, `yyyy${separator}QQQ`)}`, value: `yyyy${separator}QQQ` },
    ];

    if (fileType !== 'timesheets') {
      _dateFormatOptions.unshift(
        { label: `${format(today, `dd${separator}MM`)}`, value: `dd${separator}MM` },
        { label: `${format(today, `dd${separator}MM${separator}yy`)}`, value: `dd${separator}MM${separator}yy` },
        { label: `${format(today, `dd${separator}MM${separator}yyyy`)}`, value: `dd${separator}MM${separator}yyyy` },
      );
    }
    return _dateFormatOptions;
  }, [separator]);

  const invalidCharacters = /[\/\\|:*?"<>]/;

  const isValidFileName = fileName => {
    return !invalidCharacters.test(fileName);
  };

  useEffect(() => {
    if (!fileType || (fileType !== 'accountsReceivable' && fileType !== 'creditNotes' && fileType !== 'timesheets')) return;
    fetchFileNameSettings().then(res => {
      if (res) {
        const fileNameSettings = res?.file_name_settings ? JSON.parse(res?.file_name_settings) : null;
        if (fileNameSettings) {
          setUserSettings(fileNameSettings);
          if (fileNameSettings[fileType]) {
            const settingsSeparator = fileNameSettings[fileType]?.separator || '-';
            const settingsTemplate = fileNameSettings[fileType]?.template;
            const settingsDateFormat = fileNameSettings[fileType]?.dateFormat || dateFormatOptions[0];

            // Find the date format that should be selected from dateFormatOptions by applying the settings separator
            const dateFormatOption = dateFormatOptions.find(option => {
              if (separator === settingsSeparator) {
                return option.value === settingsDateFormat;
              } else {
                return option.value.replace(new RegExp(separator, 'g'), settingsSeparator) === settingsDateFormat;
              }
            });
            // Apply the settings separator to the selected option so it will match the dateFormatOptions element
            // after the useMemo runs and applies the new separator
            const selectedDateFormat = {
              label: dateFormatOption.label.replace(new RegExp(separator, 'g'), settingsSeparator),
              value: dateFormatOption.value.replace(new RegExp(separator, 'g'), settingsSeparator),
            };

            //The dateFormatOptions will be updated by the useMemo with a 'separator' dependency and should match the selectedDateFormat object
            setSelectedDateFormat(selectedDateFormat);
            setSeparator(settingsSeparator);
            setFileNameTemplate(settingsTemplate);
            return;
          }
        } else {
          updateFileNameSettings({
            accountsReceivable: { template: '', dateFormat: `dd${separator}MM`, separator },
            creditNotes: { template: '', dateFormat: `dd${separator}MM`, separator },
            timesheets: { template: '', dateFormat: `MM${separator}yyyy`, separator },
          });
          setUserSettings({
            accountsReceivable: { template: '', dateFormat: `dd${separator}MM`, separator },
            creditNotes: { template: '', dateFormat: `dd${separator}MM`, separator },
            timesheets: { template: '', dateFormat: `MM${separator}yyyy`, separator },
          });
        }
      } else {
        //if user has no settings for naming convention, add an empty template with default separator and date
        updateFileNameSettings({
          accountsReceivable: { template: '', dateFormat: `dd${separator}MM`, separator },
          creditNotes: { template: '', dateFormat: `dd${separator}MM`, separator },
          timesheets: { template: '', dateFormat: `MM${separator}yyyy`, separator },
        });
        setUserSettings({
          accountsReceivable: { template: '', dateFormat: `dd${separator}MM`, separator },
          creditNotes: { template: '', dateFormat: `dd${separator}MM`, separator },
          timesheets: { template: '', dateFormat: `MM${separator}yyyy`, separator },
        });
      }
      let defaultDateFormat;
      switch (fileType) {
        case 'timesheets':
          defaultDateFormat = dateFormatOptions.find(option => {
            return option.value === `MM${separator}yyyy`;
          });
          break;
        default:
          defaultDateFormat = dateFormatOptions.find(option => {
            return option.value === `dd${separator}MM`;
          });
          break;
      }
      setSelectedDateFormat(defaultDateFormat);
      return;
    });

    // Reset fileNameTemplate and dateFormat when component unmounts
    return () => {
      setFileNameTemplate('');
      setSeparator('-');
    };
  }, []);

  const handleHide = () => {
    setShow(false);
  };

  const onDownloadClick = () => {
    let _errors = [...errors]; // Copy current errors

    if (fileType === 'timesheets') {
      // Validate the presence of [ID] macro
      if (!fileNameTemplate.includes('[ID]')) {
        if (!_errors.find(err => err.type === 'ID')) {
          _errors.push({ type: 'ID', msg: 'The timesheet ID [ID] macro is required.' });
        }
      } else {
        _errors = _errors.filter(err => err.type !== 'ID'); // Remove ID error if macro is present
      }
    } else {
      // Validate the presence of [Number] macro
      if (!fileNameTemplate.includes('[Number]')) {
        if (!_errors.find(err => err.type === 'Number')) {
          _errors.push({ type: 'Number', msg: 'The Invoice Number [Number] macro is required.' });
        }
      } else {
        _errors = _errors.filter(err => err.type !== 'Number'); // Remove Number error if macro is present
      }
    }

    // Validate special characters
    if (!isValidFileName(fileNameTemplate)) {
      if (!_errors.find(err => err.type === 'Invalid character')) {
        _errors.push({
          type: 'Invalid character',
          msg: 'Invalid character. Remove any of the following characters: / \\ | ? * :',
        });
      }
    } else {
      _errors = _errors.filter(err => err.type !== 'Invalid character'); // Remove Invalid character error if no invalid characters
    }

    if (_errors.length) {
      setErrors(_errors); // Set errors if there are any
      return; // Exit the function if there are errors
    }

    // Clear errors if there are no issues
    setErrors([]);

    // Proceed if no errors
    onAccept({
      template: fileNameTemplate,
      dateFormat: selectedDateFormat.value,
      separator: separator,
    });
  };

  const onSaveTemplate = () => {
    let _errors = [...errors]; // Copy current errors

    if (fileType === 'accountsReceivable' || fileType === 'creditNotes') {
      // Validate the presence of [Number] macro
      if (!fileNameTemplate.includes('[Number]')) {
        if (!_errors.find(err => err.type === 'Number')) {
          _errors.push({ type: 'Number', msg: 'The Invoice Number [Number] macro is required.' });
        }
      } else {
        _errors = _errors.filter(err => err.type !== 'Number'); // Remove Number error if macro is present
      }
    } else if (fileType === 'timesheets') {
      // Validate the presence of [Number] macro
      if (!fileNameTemplate.includes('[ID]')) {
        if (!_errors.find(err => err.type === 'ID')) {
          _errors.push({ type: 'ID', msg: 'The timesheet ID [ID] macro is required.' });
        }
      } else {
        _errors = _errors.filter(err => err.type !== 'ID'); // Remove Number error if macro is present
      }
    }

    // Validate special characters
    if (!isValidFileName(fileNameTemplate)) {
      if (!_errors.find(err => err.type === 'Invalid character')) {
        _errors.push({
          type: 'Invalid character',
          msg: 'Invalid character. Remove any of the following characters: / \\ | ? * :',
        });
      }
    } else {
      _errors = _errors.filter(err => err.type !== 'Invalid character'); // Remove Invalid character error if no invalid characters
    }

    if (_errors.length) {
      setErrors(_errors); // Set errors if there are any
      return; // Exit the function if there are errors
    }

    // Clear errors if there are no issues
    setErrors([]);

    // Proceed if no errors
    setSavingTemplate(true);
    let newSettings = { ...userSettings };
    newSettings[fileType] = {
      template: fileNameTemplate,
      dateFormat: selectedDateFormat.value,
      separator: separator,
    };

    updateFileNameSettings(newSettings)
      .then(res => {
        setUserSettings(newSettings);
        setSavingTemplate(false);
        dispatch(showSuccessNotification('Settings saved!'));
      })
      .catch(err => {
        setSavingTemplate(false);
        setErrors([{ type: 'Save', msg: 'Failed to save settings.' }]); // Handle save error
      });
  };

  const addMacroToTemplate = macro => {
    setFileNameTemplate(prev => prev + `${macro}`);
  };

  const changeSpacing = value => {
    const newSeparator = spacingOptions[value];
    setSeparator(newSeparator);
    //everytime the separator changes, selectedDate should also update so the user sees the change in real time
    setSelectedDateFormat(prev => {
      // Create a regex pattern based on spacingOptions
      const separators = Object.values(spacingOptions).join('|');
      const separatorPattern = new RegExp(separators, 'g');

      return {
        label: prev.label.replace(separatorPattern, newSeparator),
        value: prev.value.replace(separatorPattern, newSeparator),
      };
    });
  };

  return (
    <Modal size="md" show={show} hide={handleHide} title="Change file name">
      <div className="w-full flex flex-col gap-y-6">
        <InputSimple
          placeholder="Write file name here"
          value={fileNameTemplate}
          onChange={e => setFileNameTemplate(e.target.value)}
        />
        <div className="text-lg">Variables</div>
        {(fileType === 'accountsReceivable' || fileType === 'creditNotes') && (
          <FinanceFileNameMacros addMacroToTemplate={addMacroToTemplate} />
        )}
        {fileType === 'timesheets' && <TimesheetFileNameMacros addMacroToTemplate={addMacroToTemplate} />}
        <div className="grid grid-cols-2 w-full gap-6">
          <div className="col-span-2">
            <ReactSelect
              label="Date format"
              options={dateFormatOptions}
              selectedOptions={[selectedDateFormat]}
              onChange={e => setSelectedDateFormat(e)}
              orderOptions={false}
            />
          </div>
          <div className="col-span-2">
            <InputRadio
              options={Object.keys(spacingOptions)}
              selectedValue={Object.keys(spacingOptions).find(key => separator === spacingOptions[key])}
              label={
                <div className="flex items-center">
                  <span>Separator</span>
                  <InfoTooltip>
                    <div className="flex flex-col gap-y-1">
                      <span>
                        This separator only applies to variables that you've added to the file and not to the custom text you have
                        written.
                      </span>
                      <span>A user called John Doe by default will have it's name appear as "John-Doe".</span>
                      <span>If there are multiple users, "John-Doe-Jane-Doe".</span>
                      <span>This also applies to contract references.</span>
                    </div>
                  </InfoTooltip>
                </div>
              }
              onChange={e => changeSpacing(e.target.value)}
            />
          </div>
        </div>
        {errors.length ? (
          <div className="w-full flex flex-col gap-y-1 my-4">
            {errors.map(err => {
              return <Error message={err.msg} key={err.type} />;
            })}
          </div>
        ) : (
          ''
        )}
        <div className="flex justify-between">
          <ButtonPrimary
            text={savingTemplate ? <ClipLoader className="mr-2 w-20" color={'#FFFF'} size={17} /> : 'Save template'}
            onClick={onSaveTemplate}
          />
          <div className="flex justify-end gap-x-4">
            <ButtonWhite text="Cancel" onClick={onCancel} />
            <ButtonPrimary
              text={loading ? <ClipLoader className="mr-2 w-20" color={'#FFFF'} size={17} /> : 'Accept'}
              onClick={() => onDownloadClick()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FileNameSettings;
