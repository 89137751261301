import React, { useMemo, useState, useEffect, useRef } from 'react';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import paidStatus from '../../../helpers/enum/paidStatus';
import formatCurrency from '../../../utils/formatCurrency';
import { format } from 'date-fns';
import { lineItemTypesV2 } from '../../../helpers/enum/lineItemTypes';
import InfoTooltip from '../../../components/Tooltips/InfoTooltip';
import useApi from '../../../hooks/useApi';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import { showSuccessNotification } from '../../../store/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AttachmentCard from '../../../components/Attachments/AttachmentCard';
import { ClipLoader } from 'react-spinners';
import { downloadBase64File } from '../../../helpers';
import PreviewBox from '../../PDFViewer/PreviewBox';
import { ExternalLinkIcon, ReceiptTaxIcon } from '@heroicons/react/outline';
import Loader from '../../../components/Loading/Loader';
import Checkbox from '../../../components/Checkbox/Checkbox';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import useSWR from 'swr';
import axios from 'axios';
import ChatMessage from '../../../components/Chat/ChatMessage';
import ChatSendMessage from '../../../components/Chat/ChatSendMessage';
import AccountsPayableLineItemDetails from '../../../components/Tables/AccountsPayableLineItemDetails';
import APDetailsTimesheetList from '../APDetailsTimesheetList';

const fetcher = (url, prepareData) => axios.get(url).then(res => prepareData(res));

const UserInvoiceDetails = ({
  invoice,
  lineItems,
  loadingFile,
  file,
  loadingAP,
  editApproved,
  setShowPayModal,
  edit,
  setEdit,
  handleEditClick,
  handleSaveClick,
  setShowDeleteAlert,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [preparingPreview, setPreparingPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const paidRef = useRef(null);
  const currentUser = useSelector(state => state && state.auth.currentUser);
  const { id } = useParams();

  const totals = useMemo(() => {
    let _totals = {
      total: 0,
      vat: 0,
      total_and_vat: 0,
    };

    if (lineItems.length) {
      lineItems.forEach(line => {
        _totals.total += Number(line.total);
        if (line?.vat) _totals.vat += Number(line.vat);
      });
    }

    _totals.total_and_vat = _totals.total + _totals.vat;
    return _totals;
  }, [lineItems]);

  const user = useMemo(() => {
    let _user;
    if (lineItems?.length) _user = { ...lineItems[0].user, id: lineItems[0].user_id };

    return _user;
  }, [lineItems]);

  const {
    accountsPayable: { createAPComment, deleteAccountsPayable, downloadAPFile },
  } = useApi();

  const prepareChatData = res => {
    const messages = res.data.map(message => {
      const momentOfPublish = new Date(message.created_at);
      return {
        id: message.id,
        ownerName: message.user.first_names,
        ownerImg: message.user.profilepic_url,
        ownerId: message.user_id,
        text: message.comment,
        time: momentOfPublish.toLocaleString('en-us'),
      };
    });
    return messages;
  };

  const onMessageChange = e => setCurrentComment(e.target.value);

  const sendMessageHandler = () => {
    createAPComment({ accounts_payable_id: id, comment: currentComment }).then(res => setCurrentComment(''));
  };

  const url = `/accounts-payable-comments/${id}`;

  const { data, error } = useSWR(url, url => fetcher(url, prepareChatData), {
    refreshInterval: 2000,
  });

  const deleteHandler = () => {
    deleteAccountsPayable(invoice.id).then(res => {
      dispatch(showSuccessNotification('Invoice delete'));
      history.replace('/my-invoices');
    });
  };

  const isPreviewable = fileName => {
    let bool = false;
    const extension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (extension === 'pdf' || extension === 'jpg' || extension === 'png' || extension === 'jpeg') bool = true;
    return bool;
  };

  const previewHandler = () => {
    downloadAPFile(invoice.id)
      .then(response => {
        setPreparingPreview(false);
        setShowPreview({
          url: response.data,
          show: true,
          fileType: file.resource.split('.')[file.resource.split('.').length - 1],
          title: file.resource.split('.')[file.resource.split('.').length - 2].replace('/', ''),
        });
      })
      .catch(() => {
        setPreparingPreview(false);
      });
  };

  const downloadHandler = () => {
    setDownloading(true);
    const fileName = file.resource.split('/')[file.resource.split('/').length - 1];
    downloadAPFile(invoice.id)
      .then(response => {
        downloadBase64File(response.data, fileName);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };
  const canApprove = invoice => {
    const bool = !Boolean(
      invoice?.paid !== paidStatus.numbers.unpaid ||
        lineItems?.find(li => li.type === lineItemTypesV2.accountsPayable.numbers.unapprovedProjectAllocations),
    );
    return bool;
  };

  //14 rem is to discount the admin left sidebar and 22rem the details sidebar on the right
  const STYLES = {
    width: `calc(100vw - 14rem - 22rem)`, // Full width minus sidebars width
  };

  const invoicedTimesheets = useMemo(() => {
    let _invoicedTimesheets = [];
    lineItems.forEach(li => {
      if (li.timesheet && !_invoicedTimesheets.find(it => it.id === li.timesheet.id)) _invoicedTimesheets.push(li.timesheet);
    });
    return _invoicedTimesheets;
  }, [lineItems]);

  return (
    <>
      <div className="flex h-full bg-white">
        <SimpleAlert
          errorTitle={'Delete invoice?'}
          errorMsg={
            'By continuing you will permanently delete this invoice including the file you have uploaded, any custom lines you may have created and all the comments. Other line items like allocations and milestones will still be available to invoice again.'
          }
          show={showDeleteModal}
          hide={() => setShowDeleteModal(false)}
          onAcceptText={'Delete'}
          onAcceptClick={() => deleteHandler()}
          onDeclineText={'Cancel'}
          onDeclineClick={() => setShowDeleteModal(false)}
        />
        <PreviewBox
          filePath={showPreview.url}
          fileType={showPreview.fileType}
          showPreview={showPreview.show}
          handleHide={() => setShowPreview(false)}
          title={showPreview.title}
        />
        <div className="w-full h-full flex flex-col justify-between px-4 pb-4" style={STYLES}>
          <div>
            <h3>Details</h3>
            <Loader isLoading={loadingAP} className="flex justify-center m-8 h-full">
              <dl className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
                <SimpleEntry
                  label="User"
                  data={
                    user ? (
                      <div className="flex items-center gap-x-1">
                        <span>{user?.full_name}</span>
                        <ExternalLinkIcon
                          className="h-5 w-5 cursor-pointer text-thaleria-orange-500 hover:text-thaleria-orange-300"
                          onClick={() => window.open(`/admin-panel/hr/user-directory/${user.id}`)}
                        />
                      </div>
                    ) : (
                      '-'
                    )
                  }
                />
                <SimpleEntry label="Invoice number" data={invoice.invoice_number || '-'} />
                <SimpleEntry label="Due date" data={invoice?.due_date ? format(new Date(invoice.due_date), 'dd/MM/yyyy') : '-'} />
                <SimpleEntry label="IBAN" data={invoice.iban ? invoice.iban : '-'} />
                <SimpleEntry label="BIC SWIFT" data={invoice.bic_swift ? invoice.bic_swift : '-'} />
                <SimpleEntry label="Company name" data={invoice?.company_name ? invoice.company_name : '-'} />
                <SimpleEntry label="Amount match" data={invoice.amount_match ? 'Yes' : 'No'} />
                <SimpleEntry
                  label="Uploaded on"
                  data={invoice?.created_at ? format(new Date(invoice.created_at), 'dd/MM/yyyy') : '-'}
                />
                <div className="col-span-1">
                  <div className="flex items-center mb-1 gap-x-2">
                    <span className="text-sm ">Invoice file</span>
                  </div>
                  <ul className="divide-y divide-thaleria-blue-200/75 border border-gray-200 rounded-md">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm ">
                      {!loadingFile ? (
                        file ? (
                          <AttachmentCard
                            document={file}
                            enableDownload
                            isPreviewable={isPreviewable}
                            preparingPreview={preparingPreview}
                            onPreviewHandler={previewHandler}
                            downloading={downloading}
                            onDownloadHandler={downloadHandler}
                            enableRemove={false}
                            fileIcon={'invoice'}
                          />
                        ) : (
                          <div className="flex justify-start">
                            <ReceiptTaxIcon className="w-5 h-5 text-gray-500" />
                            <span className="ml-2 text-gray-600">No invoice found</span>
                          </div>
                        )
                      ) : (
                        <div className="flex justify-center w-full">
                          <ClipLoader className="mr-10 h-5 w-5" size={18} color={'#FFA53B'} />
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </dl>
              <h3>Line items</h3>
              <div className="">
                <AccountsPayableLineItemDetails lineItems={lineItems} totals={totals} />
              </div>
            </Loader>
          </div>
          <div className="">
            <div className="flex flex-row-reverse justify-between md:col-span-2">
              <div className="text-right">
                {!edit && (
                  <div>
                    <ButtonWhite style="mr-2" onClick={() => history.push('/admin-panel/finance/accounts-payable')} text="Back" />
                    <ButtonPrimary onClick={handleEditClick} text="Edit" disable={invoice?.approved} />
                  </div>
                )}
                {edit && (
                  <div>
                    <ButtonWhite style="mr-2" onClick={() => setEdit(false)} text="Cancel changes" />
                    <ButtonPrimary onClick={handleSaveClick} text="Save changes" />
                  </div>
                )}
              </div>
              <div className="text-left">
                {!edit && (
                  <ButtonPrimary canDelete color="red" style="mr-2" onClick={() => setShowDeleteAlert(true)} text="Delete" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[22rem] px-4 bg-gray-100 flex flex-col border-l border-gray-200">
          <h3>Status</h3>
          <dl className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Checkbox
              title={
                canApprove(invoice) ? (
                  'Approved'
                ) : (
                  <div className="flex items-center">
                    Approved <InfoTooltip size="xs">Approve all included timesheets to unlock</InfoTooltip>
                  </div>
                )
              }
              value={invoice?.approved ? true : false}
              onChange={() => editApproved()}
              disabled={!canApprove(invoice)}
            />
            <Checkbox
              id={invoice?.id}
              ref={paidRef}
              title={
                invoice?.approved ? (
                  'Payment status'
                ) : (
                  <div className="flex items-center">
                    Payment status <InfoTooltip size="xs">Approve invoice to unlock</InfoTooltip>
                  </div>
                )
              }
              value={invoice?.paid === paidStatus.numbers.unpaid ? false : true}
              indeterminate={invoice?.paid === paidStatus?.numbers.partiallyPaid ? true : false}
              onChange={() => setShowPayModal(true)}
              disabled={!invoice?.approved}
            />
            <SimpleEntry label="Paid amount" data={invoice.paid_amount ? formatCurrency(invoice.paid_amount) : '-'} />
            <SimpleEntry
              label="Payment date"
              data={invoice?.payment_date ? format(new Date(invoice.payment_date), 'dd/MM/yyyy') : '-'}
            />
          </dl>
          <APDetailsTimesheetList timesheets={invoicedTimesheets} />
          <div className="flex flex-col shrink">
            <h3>Comments</h3>
            {data && data.length ? (
              <ul role="list" id="chat-container" className="space-y-6 overflow-y-auto shrink h-48">
                {data.map(message => (
                  <ChatMessage
                    chatId={message.id}
                    text={message.text}
                    time={message.time}
                    // ownerImg={message.ownerImg}
                    ownerName={message.ownerName}
                    ownerId={message.ownerId}
                    createdAt={message.time}
                  />
                ))}
              </ul>
            ) : (
              <div className="text-sm text-gray-500 mb-auto">No comments to display.</div>
            )}
            <div className="pt-4 mt-4 border-t border-gray-200 ">
              <ChatSendMessage
                // ImgUrl={currentUser.profilepic_url}
                sendMessage={sendMessageHandler}
                onBodyChange={onMessageChange}
                body={currentComment}
                textAreaErrorMessage={null}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInvoiceDetails;
